import { DateTime } from 'luxon'

const getIsDiwali = (dateIST: DateTime) => {
  /* Diwali happens between 6pm and 10pm IST on the 31st of October and 1st of November */
  const dayIST = dateIST.day
  const monthIST = dateIST.month
  const hourIST = dateIST.hour

  const isCorrectDay =
    (dayIST === 31 && monthIST === 10) || (dayIST === 1 && monthIST === 11)

  const isCorrectHour = hourIST >= 18 && hourIST < 22

  return isCorrectDay && isCorrectHour
}

export const isChatOpenFromRegularSchedule = (date = DateTime.now()) => {
  /* Since all our chat agents are in IST timezone and to avoid issues during the switch between 
  summer time and daylight savings, we always set the Date object to be in Asia/Kolkata - IST */
  const dateIST = date.setZone('Asia/Kolkata')
  const isDiwali = getIsDiwali(dateIST)

  if (isDiwali) {
    return false
  }

  /* Chat is closed between Saturday 6:30am and Monday 6:30am */
  const weekdayIST = dateIST.weekday
  const hourIST = dateIST.hour
  const minutesIST = dateIST.minute
  const isMonday = weekdayIST === 1
  const isSaturday = weekdayIST === 6
  const isSunday = weekdayIST === 7

  const isClosedOnSaturday =
    isSaturday && (hourIST > 6 || (hourIST === 6 && minutesIST >= 30))
  const isClosedOnMonday =
    isMonday && (hourIST < 6 || (hourIST === 6 && minutesIST < 30))

  if (isClosedOnSaturday || isSunday || isClosedOnMonday) {
    return false
  }
  return true
}

export const getChatStatus = () => {
  return isChatOpenFromRegularSchedule()
}
