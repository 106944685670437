import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PersonalBillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { useToasts } from 'pages/subscriptions/hooks/useToasts'
import { closeVisibleModal } from 'modules/modals'
import { useDispatch } from 'react-redux'
import { QueryKey } from 'store/types'

const billingSubscriptionsApi = new PersonalBillingSubscriptionsApi()

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient()
  const { updateSubscriptionErrorToast, updateSubscriptionSuccessToast } =
    useToasts()
  const dispatch = useDispatch()

  const updateSubscription = useMutation({
    mutationFn: billingSubscriptionsApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_CARDS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_OPEN_INVOICES],
      })
      updateSubscriptionSuccessToast()
      dispatch(closeVisibleModal())
    },
    onError: () => updateSubscriptionErrorToast(),
  })

  return {
    updateSubscription: updateSubscription.mutate,
    isSubscriptionUpdateLoading: updateSubscription.isPending,
  }
}
