import React from 'react'
import { createDividerOption } from '@ubnt/ui-components/Dropdown/Options'
import { DropdownOption } from '@ubnt/ui-components/Dropdown/types'

import { ParsedNCAConsole, SupportConsole } from 'features/support/unifi/types'
import { ZDFormValueType } from 'features/support/unifi/config/types'
import { formatMac } from 'utils/formatMac'

import { FormDropdown } from './Dropdown'

export const DeviceDropdown: React.FC<{
  userConsoles: ParsedNCAConsole[]
  otherOption: ZDFormValueType
  handleChange: (option: DropdownOption) => void
  value?: SupportConsole
}> = ({ userConsoles, otherOption, handleChange, value }) => {
  const dropdownOptions: DropdownOption[] = userConsoles.map((console) => ({
    value: console.mac || console.id,
    mac: console.mac,
    label: console.deviceName || console.label,
    additionalInfo: formatMac(console.mac),
    id: console.id,
  }))

  const otherDropdownOption = {
    value: otherOption.id,
    label: otherOption.label,
    id: otherOption.id,
  }

  const optionDivider = createDividerOption()

  const options = [
    otherDropdownOption,
    optionDivider,
    ...dropdownOptions,
  ] as DropdownOption[]

  return (
    <FormDropdown options={options} handleChange={handleChange} value={value} />
  )
}
