import React from 'react'
import SearchIcon from '@ubnt/icons/SearchIcon'
import { Text } from '@ubnt/ui-components/aria'
import { FlexWrapper } from 'components/SharedComponents'
import { FormattedMessage } from 'react-intl'

export const EmptySearchResults: React.FC = () => {
  return (
    <FlexWrapper
      column
      gap={8}
      alignItems="center"
      justifyContent="center"
      marginTop={80}
      marginBottom={80}
    >
      <SearchIcon isActive size={48} />
      <Text variant="heading-medium" color="text-1">
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_NO_SEARCH_RESULTS_TITLE" />
      </Text>
      <Text variant="body-primary" color="text-2">
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_NO_SEARCH_RESULTS_DESCRIPTION" />
      </Text>
    </FlexWrapper>
  )
}
