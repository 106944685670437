import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PersonalBillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { closeVisibleModal } from 'modules/modals'
import { useDispatch } from 'react-redux'
import { QueryKey } from 'store/types'

const billingSubscriptionsApi = new PersonalBillingSubscriptionsApi()

export const useCancelLteSubscription = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const cancelLteSubscription = useMutation({
    mutationFn: billingSubscriptionsApi.cancelLte,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_INVOICES],
      })
      dispatch(closeVisibleModal())
    },
  })

  return {
    cancelLteSubscription: cancelLteSubscription.mutate,
    isCancelLteSubscriptionLoading: cancelLteSubscription.isPending,
    cancelLteSubscriptionErrors: cancelLteSubscription.error,
    resetCancelLteSubscriptionErrors: cancelLteSubscription.reset,
  }
}
