import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { PersonalBillingInvoicesApi } from 'api/billing/billingInvoices'

const billingInvoicesApi = new PersonalBillingInvoicesApi()

export const useBillingInvoiceLinesQuery = (
  id: string = '',
  region: StripeRegionCode = StripeRegionCode.US,
  hasMoreInvoiceLines: boolean = false
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const shouldFetchExtraLines = isDoneAuthenticating && hasMoreInvoiceLines

  const extraInvoiceLines = useQuery({
    queryKey: [QueryKey.BILLING_INVOICE_LINES, id],
    queryFn: () => billingInvoicesApi.getAllInvoiceLines({ id, region }),
    enabled: shouldFetchExtraLines,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    extraInvoiceLines: extraInvoiceLines.data,
    refetchInvoiceLines: extraInvoiceLines.refetch,
    areInvoiceLinesLoading: extraInvoiceLines.isFetching,
  }
}
