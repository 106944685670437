import React, { useMemo } from 'react'
import { Alert } from '@ubnt/ui-components/Alert'
import { Text } from '@ubnt/ui-components/aria'
import { media } from 'theme'
import styled from 'theme/styled'
import { FlexWrapper } from 'components/SharedComponents'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { StripeStatus } from 'sharedConstants'
import { CountryFlag } from 'components/CountryFlag'
import { getAmount } from '../InvoicesTable/utils'
import { FormattedMessage, useIntl } from 'react-intl'
import { InvoiceItem } from 'pages/subscriptions/hooks/utils'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { formatCurrencyNumberOptions } from 'utils/formatCurrencyNumberOptions'

type Props = {
  selectedMonthInvoices?: InvoiceItem
}

type PaymentData = {
  amount: number
  region: StripeRegionCode
}

export const UnpaidInvoicesAlert: React.FC<Props> = ({
  selectedMonthInvoices,
}) => {
  const intl = useIntl()

  const unpaidInvoices = useMemo(() => {
    const invoices: Record<string, PaymentData> = {}

    selectedMonthInvoices?.children.forEach((invoice) => {
      if (invoice.status === StripeStatus.OPEN) {
        if (invoices[invoice.currency]) {
          invoices[invoice.currency].amount += invoice.amount_due
        } else {
          invoices[invoice.currency] = {
            amount: invoice.amount_due,
            region: invoice.region,
          }
        }
      }
    })

    return invoices
  }, [selectedMonthInvoices?.children])

  if (!selectedMonthInvoices || selectedMonthInvoices.totalUnpaid < 1) {
    return null
  }

  return (
    <Alert
      Icon={<StyledExclamationMarkIcon variant="fill" />}
      text={
        <AlertTextContainer>
          <Text
            color="text-2"
            variant="heading-medium"
            style={{ marginRight: '4px' }}
          >
            <FormattedMessage id="SETTINGS_INVOICES_UNPAID_ACCORDION_LABEL" />
          </Text>
          <FlexWrapper gap={12} alignItems="center" flexWrap="wrap">
            {Object.entries(unpaidInvoices).map(
              ([currency, { amount, region }]) => {
                const hasMultiplePaymentRegions =
                  Object.entries(unpaidInvoices).length > 1
                return (
                  <PaymentAmount
                    color="text-1"
                    variant="heading-medium"
                    key={`amount-${region}`}
                  >
                    {hasMultiplePaymentRegions && (
                      <CountryFlag countryCode={region} noMargin />
                    )}
                    {intl.formatNumber(
                      getAmount(amount, currency),
                      formatCurrencyNumberOptions(currency)
                    )}
                  </PaymentAmount>
                )
              }
            )}
          </FlexWrapper>
          <Text color="text-3" variant="body-secondary">
            <FormattedMessage
              id="SETTINGS_INVOICES_LABEL_WITH_PREFIX"
              values={{ amount: selectedMonthInvoices.totalUnpaid }}
            />
          </Text>
        </AlertTextContainer>
      }
    />
  )
}

const AlertTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${media.xSmall}) {
    flex-direction: column;
    align-items: flex-start;

    > *:not(:first-child) {
      margin-left: -28px;
    }
  }
`

const PaymentAmount = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledExclamationMarkIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.red06};
`
