import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { selectProfileData } from 'modules/profile'
import { UcoreDevice } from 'api/nca/types'
import { SupportFormState } from 'features/support/unifi/types'

import { Console, FormConfig } from '../config/types'
import {
  getChatDepartment,
  getFormattedMessage,
  getTagsToAdd,
  getTagsToRemove,
} from './chatUtils'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { ORGANIZATION_ACCOUNT_TAG } from 'features/support/constants'

export const useFormatChatMessage = (config: FormConfig) => {
  const profile = useSelector(selectProfileData)
  const intl = useIntl()
  const { accountType } = useOrganizationsQuery()

  const formatChatMessage = (
    values: SupportFormState,
    visibleSections: string[],
    isSupportFileAllowed: boolean,
    selectedConsole?: UcoreDevice,
    sendsafelyAttachmentUrl?: string
  ) => {
    const consoleFWVersion =
      selectedConsole?.shadow?.state?.reported?.hardware?.firmwareVersion

    const consoleName = selectedConsole?.shadow?.state?.reported?.name

    const startChatButton = document.querySelector('#bubble-btn') as HTMLElement
    startChatButton?.click?.()
    startChatButton.classList.add('btn-visible')

    const message = getFormattedMessage(
      config,
      values,
      visibleSections,
      intl,
      isSupportFileAllowed,
      consoleName,
      consoleFWVersion,
      sendsafelyAttachmentUrl
    )

    const departmentObject = getChatDepartment(
      values.tech,
      values.app,
      values.model,
      values.trouble,
      values.device,
      values.console?.id as Console
    )

    const sendMessageEvent = new CustomEvent('MyEventType', {
      detail: {
        message,
        a: message,
        email: profile?.email,
        departmentObject,
      },
    })

    const widgetInput =
      (document.querySelector('#send-message') as HTMLTextAreaElement) || null

    if (widgetInput?.disabled) {
      window?.zChat.endChat()
    }

    window?.dispatchEvent(sendMessageEvent)

    const tagsToRemove = getTagsToRemove()
    const tagsToAdd = getTagsToAdd(values)

    if (accountType === AccountType.ORG_OWNER) {
      tagsToAdd.push(ORGANIZATION_ACCOUNT_TAG)
    }

    window?.zChat?.removeTags(tagsToRemove)
    window?.zChat?.addTags(tagsToAdd)

    const visitor = {
      display_name: profile?.username,
      email: profile?.email,
    }

    window?.zChat?.setVisitorInfo(visitor)
  }

  return { formatChatMessage }
}
