import { useContext } from 'react'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'
import {
  OrganizationBillingSubscriptionsApi,
  PersonalBillingSubscriptionsApi,
} from 'api/billing/billingSubscriptions'

export const useBillingSubscriptionsApi = () => {
  const { orgToFetch } = useContext(OrganizationContext)

  if (orgToFetch) {
    return new OrganizationBillingSubscriptionsApi(orgToFetch)
  }

  return new PersonalBillingSubscriptionsApi()
}
