import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import { typography } from 'theme'
import styled from 'theme/styled'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { useBillingUiTaxIdQuery } from 'store/queries/useBillingUiTaxIdQuery'

export const UiTaxIds: React.FC<{ region: StripeRegionCode }> = ({
  region,
}) => {
  const { uiTaxIds, areUiTaxIdsLoading } = useBillingUiTaxIdQuery()
  const regionTaxIds = uiTaxIds.find((taxIds) => taxIds.region === region)

  if (areUiTaxIdsLoading) {
    return (
      <SkeletonLoader>
        <Wrapper>
          <UiTaxIdHeader>Tax Ids</UiTaxIdHeader>
          <Text width={180} />
        </Wrapper>
      </SkeletonLoader>
    )
  }

  if (!regionTaxIds) {
    return null
  }

  return (
    <>
      <UiTaxIdHeader>Tax Ids</UiTaxIdHeader>
      <UiTaxIdsWrapper>
        {regionTaxIds.ids.map((item, i) => (
          <span key={i}>{item}</span>
        ))}
      </UiTaxIdsWrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const UiTaxIdHeader = styled.span`
  margin-top: 4px;
  font: ${typography['desktop-typography-heading-large']};
`

const UiTaxIdsWrapper = styled.div`
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2px;
  column-gap: 8px;
  justify-content: flex-end;
  align-self: flex-end;
`
