import { UcoreDevice } from 'api/nca/types'
import { CurrencyRegion } from 'features/invoices/ui/types'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { isString } from 'lodash-es'
import { ProductName } from 'types/enums'
import { isCard } from '../UnpaidInvoicesAccordion/types'
import { DateTime } from 'luxon'
import { SITE_NAME_TO_HIDE } from '../../subscriptions/utils'

const DEVICE_NAME_FALLBACK = '-'

export const getDeviceName = (
  invoice: StripeInvoice,
  devices: UcoreDevice[],
  productLine: ProductName
) => {
  const uiDeviceName = invoice.subscription_details?.metadata?.ui_device_name
  const controller = invoice.subscription_details?.metadata?.controller
  const site =
    invoice.subscription_details?.metadata?.site ??
    invoice.subscription_details?.metadata?.site_name
  const deviceName =
    invoice.subscription_details?.metadata?.deviceName ??
    invoice.subscription_details?.metadata?.device_name
  const deviceId = invoice.subscription_details?.metadata?.device_uuid
  const deviceShortname =
    invoice.subscription_details?.metadata?.device_shortname
  const subscriptionDevice = devices.find(
    (device) => device.hardwareId === deviceId
  )

  if (productLine === ProductName.ULTE && controller) {
    if (site && site !== SITE_NAME_TO_HIDE) {
      return `${controller} - ${site}`
    }
    return controller
  }

  if (productLine === ProductName.CYBERSECURE) {
    if (site && site !== SITE_NAME_TO_HIDE && deviceName) {
      return `${deviceName} - ${site}`
    }
    if (deviceName) {
      return deviceName
    }
    if (deviceShortname) {
      return deviceShortname
    }
    return DEVICE_NAME_FALLBACK
  }

  if (productLine === ProductName.UNIFI_ACCESS && invoice.metadata?.name) {
    return invoice.metadata.name
  }

  if (uiDeviceName) {
    return uiDeviceName
  }

  if (deviceName) {
    return deviceName
  }

  if (subscriptionDevice && subscriptionDevice?.shadow?.state.reported.name) {
    return subscriptionDevice?.shadow?.state.reported.name
  }

  return DEVICE_NAME_FALLBACK
}

export const getAmount = (price: number, currency?: string) => {
  if (currency === CurrencyRegion.JPY) {
    return price
  }

  return price / 100
}

export const getBilledCard = (child: StripeInvoice) => {
  if (isString(child.charge)) return '—'

  if (isCard(child?.charge?.payment_method_details)) {
    const last4 = child?.charge?.payment_method_details?.card?.last4
    return last4 ? `····${last4}` : '—'
  }

  return '—'
}

export const getDate = (date: number | null) => {
  if (!date) {
    return '---'
  }
  return DateTime.fromSeconds(date).toFormat('MMM d, yyyy')
}
