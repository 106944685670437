import React from 'react'
import { Text } from '@ubnt/ui-components/aria'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import styled from 'theme/styled'
import {
  OrganizationManagerDropdown,
  StyledImage,
} from './OrganizationManagerDropdown'

export const OrganizationManager = () => {
  const { accountType, organizations, isOwnerAndAdmin } =
    useOrganizationsQuery()

  if (accountType === AccountType.PERSONAL_ACCOUNT) {
    return null
  }

  if (
    accountType === AccountType.ORG_OWNER &&
    organizations &&
    !isOwnerAndAdmin
  ) {
    return (
      <OrgOwnerWrapper>
        <StyledImage src={organizations[0].logoUrl} />
        <Text color="text-1">{organizations[0].name}</Text>
      </OrgOwnerWrapper>
    )
  }

  return <OrganizationManagerDropdown />
}

const OrgOwnerWrapper = styled.div`
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.neutral03};
  border-radius: 4px;
`
