import React from 'react'
import { useIntl } from 'react-intl'
import type Stripe from 'stripe'
import { typography } from 'theme'
import styled from 'theme/styled'
import { formatCurrencyNumberOptions } from 'utils/formatCurrencyNumberOptions'

type InvoiceTaxBreakdownProps = {
  metadata: Stripe.Metadata
  lines: Stripe.Invoice['lines']['data']
  currency: string
}

const TAX_IDENTIFIER = 'ui_tax: '
const FIELD_TO_FILTER_OUT = 'ui_tax: Total Cents'
const TAX_ITEM_VALUES = ['Taxes, Fees and Surcharges', 'VAT (20%)']

export const InvoiceTaxBreakdown: React.FC<InvoiceTaxBreakdownProps> = ({
  metadata,
  lines,
  currency,
}) => {
  const intl = useIntl()
  const hasTaxItem = lines.some((item) =>
    TAX_ITEM_VALUES.includes(item.description ?? '')
  )
  const uiTaxFields = Object.keys(metadata).filter(
    (field) => field.startsWith(TAX_IDENTIFIER) && field !== FIELD_TO_FILTER_OUT
  )

  if (!uiTaxFields || !hasTaxItem) {
    return null
  }

  return (
    <Wrapper>
      <Title>Taxes and fees</Title>
      {uiTaxFields.map((field, i) => (
        <div key={i}>
          <span>{field.replace(TAX_IDENTIFIER, '')}: </span>
          <TaxAmount>
            {intl.formatNumber(
              typeof metadata[field] === 'number' ? metadata[field] : 0,
              formatCurrencyNumberOptions(currency)
            )}
          </TaxAmount>
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
`

const Title = styled.span`
  font: ${typography['desktop-typography-heading-large']};
`

const TaxAmount = styled.span`
  font-weight: 700;
`
