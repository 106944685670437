import { UcoreDevice } from 'api/nca/types'
import { ParsedNCAConsole, SupportConsole } from 'features/support/unifi/types'

import { ZDFormValueType } from '../config/types'
import { getConsoleZdId } from 'features/support/utils'

const hasAppInstalled = (app: string, controllers: any[]): boolean => {
  return controllers.some(
    (controller) => controller.name === app && controller.isInstalled
  )
}

const parseNCADevice = (device?: UcoreDevice): SupportConsole | undefined => {
  const shadow = device?.shadow?.state?.reported

  if (!shadow) return
  const mac = shadow.mac

  const hasNetwork = hasAppInstalled('network', shadow.controllers)
  const hasTalk = hasAppInstalled('talk', shadow.controllers)
  const hasProtect = hasAppInstalled('protect', shadow.controllers)
  const hasUID = hasAppInstalled('uid-agent', shadow.controllers)
  const hasConnect = hasAppInstalled('connect', shadow.controllers)
  const hasAccess = hasAppInstalled('access', shadow.controllers)
  const hasDrive = hasAppInstalled('drive', shadow.controllers)
  const hasTalkRelay = hasAppInstalled('talk-relay', shadow.controllers)

  return {
    deviceId: device?.id,
    id: getConsoleZdId(shadow?.hardware?.shortname),
    mac,
    hasNetwork,
    hasTalk,
    hasProtect,
    hasUID,
    hasConnect,
    hasAccess,
    hasDrive,
    hasTalkRelay,
  }
}

export const parseNCAdata = (
  devices: UcoreDevice[],
  deviceTiles: ZDFormValueType[]
): ParsedNCAConsole[] => {
  return devices
    .map((device) => {
      const shadow = device.shadow?.state?.reported

      const NCAData = parseNCADevice(device)

      if (!shadow || !NCAData) return

      const originalTile = deviceTiles.find((tile) => tile.id === NCAData.id)

      if (!originalTile) return

      return {
        ...originalTile,
        ...NCAData,
        deviceName: shadow.name,
      }
    })
    .filter((device) => device) as ParsedNCAConsole[]
}
