export enum Route {
  Support = 'mysupport',
  Profile = 'profile',
  Security = 'security',
  Payments = 'payments',
  Subscriptions = 'subscriptions',
  Backups = 'backups',
  Community = 'community',
  Store = 'store',
  TermsAndConditions = 'terms',
  PrivacyPolicy = 'privacy',
  UICom = 'ui.com',
  UniFi = 'UniFi',
  UISP = 'UISP',
  SupportForm = 'supportForm',
  RMA = 'rma',
  FaqPortal = 'faqPortal',
  MyRequests = 'myRequests',
  SupportAdmin = 'supportAdmin',
}

export const ROUTES = {
  [Route.Backups]: '/backups',
  [Route.Payments]: '/payments',
  [Route.Subscriptions]: '/subscriptions',
  [Route.Profile]: '/profile',
  [Route.Security]: '/security',
  [Route.Support]: '/mysupport',
  [Route.SupportForm]: '/supportform',
  [Route.MyRequests]: '/requests',
  [Route.SupportAdmin]: '/support-admin',

  // External Routes
  [Route.Community]: 'https://community.ui.com/',
  [Route.FaqPortal]: 'https://help.ui.com/hc/en-us/categories/6583256751383',
  [Route.PrivacyPolicy]: 'https://ui.com/legal/privacypolicy/',
  [Route.RMA]: 'https://rma.ui.com/',
  [Route.Store]: 'https://store.ui.com/',
  [Route.TermsAndConditions]: 'https://ui.com/legal/termsofservice/',
  [Route.UICom]: 'https://ui.com',
  [Route.UISP]: 'https://uisp.ui.com',
  [Route.UniFi]: 'https://unifi.ui.com',
}

export interface IMobileNavigationConfig {
  main: INavigationItem[]
  other: INavigationItem[]
}

export type IMobileNavigationKey = 'main' | 'other'

export interface INavigationItem {
  Icon?: React.FC
  id: Route
  link: string
  title: React.ReactNode
  newTab?: boolean
  hiddenForAdmins?: boolean
}
