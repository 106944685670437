import { useInfiniteQuery } from '@tanstack/react-query'
import { PageParams } from 'api/billing/billingInvoices'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'
import { InvoiceData } from 'features/subscriptions/module/types'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { useBillingInvoiceYearsQuery } from './useBillingInvoiceYearsQuery'
import { useBillingInvoicesApi } from 'pages/subscriptions/hooks/useBillingInvoicesApi'

const getNextPage = (prevData: InvoiceData) => {
  const nextPages: PageParams = []
  Object.entries(prevData).forEach(([region, { data, has_more }]) => {
    if (has_more) {
      nextPages.push({
        region: region as StripeRegionCode,
        startingAfter: data[data.length - 1].id,
      })
    }
  })

  return nextPages
}

export const useBillingInvoicesQuery = (year?: string) => {
  const { invoiceYears } = useBillingInvoiceYearsQuery()
  const billingInvoicesApi = useBillingInvoicesApi()

  const yearToFetch = year ?? invoiceYears[0]

  const rawInvoices = useInfiniteQuery({
    queryKey: [QueryKey.BILLING_INVOICES, yearToFetch],
    queryFn: billingInvoicesApi.getPaginated,
    initialPageParam: [{ startingAfter: null, region: null }],
    getNextPageParam: getNextPage,
    enabled: !!invoiceYears.length,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    fetchNextInvoicesPage: rawInvoices.fetchNextPage,
    areInvoicesLoading: rawInvoices.isLoading,
    areInvoicesRefetching: rawInvoices.isFetching,
    areInvoicesFetched: rawInvoices.isFetched,
  }
}
