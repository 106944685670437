import React from 'react'
import { api } from 'api'
import axios from 'axios'
import { ZDCustomFields } from './types'
import { UcoreDevice } from 'api/nca/types'
import { UidbImage_Console } from '@ubnt/ui-components/aria/UidbImage'
import { ZDFormValueType as UniFiZdFormFValueType } from './unifi/config/types'
import { ZDFormValueType as BillingZdFormValueType } from './billing/config/types'
import { Console, Model } from './unifi/config/types'

export const handleCreateRequestError = async (
  error: unknown,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  formattedRequest:
    | {
        ticket_form_id: number
        requester?: {
          email?: string
          name?: string
        }
        tags: string[]
        subject?: string
        comment: { body?: string }
        custom_fields?: ZDCustomFields
      }
    | undefined,
  setIsFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // In case of submitting again for users to be able to see the toast again
  setErrorMessage('')
  if (!axios.isAxiosError(error)) {
    return setErrorMessage('SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION')
  }

  //   // set error message for zendesk rate-limit
  if (error.response?.status === 429) {
    return setErrorMessage('SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_RATE_LIMIT')
  }
  //    Check if the browser blocked the request due to strict mode
  //    in such cases, a Network Error message is received and no we get no error response
  //    as the request is prevented from reaching its destination so it never made it.
  //    */
  if (error?.message === 'Network Error' && !error?.response) {
    return setErrorMessage(
      'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_STRICT_MODE'
    )
  }

  if (error?.response?.status === 422 && formattedRequest) {
    /*
     422 errors happen when the user information does not exist in
     Zendesk or does not match the information in SSO 
    */
    await api.createOrUpdateZendeskUser()
    await api.createZendeskRequest(formattedRequest)
    setIsFormSubmitted(true)
  } else {
    return setErrorMessage('SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION')
  }
}

export const getIsUnas = (console?: Console, model?: Model) => {
  const unasConsoleIds = [getConsoleZdId('UNASPRO'), getConsoleZdId('UNAS-Pro')]
  if (
    console &&
    (console === Console.unifiNas || unasConsoleIds.includes(console))
  ) {
    return true
  }

  if (model === Model.unifiNas) {
    return true
  }

  return false
}

export const getConsoleZdId = (shortname?: string): string => {
  if (!shortname) {
    return ''
  }

  return `uosfrm_cons_${shortname.toLowerCase()}`
}

export const parseNcaDevices = (
  ncaDevices: UcoreDevice[]
): (UniFiZdFormFValueType | BillingZdFormValueType)[] => {
  return ncaDevices.map((device) => {
    const { shortname } = device.shadow.state.reported.hardware

    return {
      id: getConsoleZdId(shortname),
      label: shortname,
      icon: <UidbImage_Console shortname={shortname} size="60px" />,
    }
  })
}
