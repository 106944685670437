import React from 'react'
import { UidbImage_Console } from '@ubnt/ui-components/aria'
import { HomeIcon, OfficeIcon } from '@ubnt/icons'

import {
  BillingSpecificService,
  Console,
  UserType,
  ZDFormValueType,
} from './types'

export const billingSpecificService: ZDFormValueType[] = [
  { id: BillingSpecificService.lte, label: 'UniFi LTE' },
  { id: BillingSpecificService.cloud, label: 'Official UniFi Hosting' },
  { id: BillingSpecificService.talk, label: 'UniFi Talk' },
  { id: BillingSpecificService.identity, label: 'UniFi Identity Enterprise' },
  { id: BillingSpecificService.umr, label: 'UniFi Mobility / UMR' },
]

export const console: ZDFormValueType[] = [
  {
    id: Console.uck,
    label: 'UC CK',
    icon: <UidbImage_Console shortname="UCK" size="60px" />,
  },
  {
    id: Console.uckG2,
    label: 'UCK G2',
    icon: <UidbImage_Console shortname="UCKG2" size="60px" />,
  },
  {
    id: Console.uckG2Plus,
    label: 'UCK G2+',
    icon: <UidbImage_Console shortname="UCK-G2-PLUS" size="60px" />,
  },
  {
    id: Console.udm,
    label: 'UDM',
    icon: <UidbImage_Console shortname="UDM" size="60px" />,
  },
  {
    id: Console.udmPro,
    label: 'UDM PRO',
    icon: <UidbImage_Console shortname="UDMPRO" size="60px" />,
  },
  {
    id: Console.udmSe,
    label: 'UDM SE',
    icon: <UidbImage_Console shortname="UDMSE" size="60px" />,
  },
  {
    id: Console.udmProMax,
    label: 'UDM PRO MAX',
    icon: <UidbImage_Console shortname="UDMPROMAX" size="60px" />,
  },
  {
    id: Console.udr,
    label: 'UDR',
    icon: <UidbImage_Console shortname="UDR" size="60px" />,
  },
  {
    id: Console.udw,
    label: 'UDW',
    icon: <UidbImage_Console shortname="UDW" size="60px" />,
  },
  {
    id: Console.ucgUltra,
    label: 'UCG Ultra',
    icon: <UidbImage_Console shortname="UCG-Ultra" size="60px" />,
  },
  {
    id: Console.ux,
    label: 'Express',
    icon: <UidbImage_Console shortname="UX" size="60px" />,
  },
  {
    id: Console.cloud,
    label: 'UniFi Cloud Console',
    icon: <UidbImage_Console shortname="CLOUD" size="60px" />,
  },
  {
    id: Console.other,
    label: 'Other',
  },
]

export const userType: ZDFormValueType[] = [
  {
    id: UserType.home,
    label: 'Home User',
    icon: <HomeIcon size="large" />,
  },
  {
    id: UserType.business,
    label: 'Business User',
    icon: <OfficeIcon size="large" />,
  },
]
