import { AppHeader } from '@ubnt/ui-components'
import { Link } from 'react-router-dom'
import styled from 'theme/styled'

export const StyledLink = styled(Link)`
  width: 50px;
  background: transparent;

  svg {
    height: 32px;
    width: 32px;
  }

  :hover {
    color: ${(p) => p.theme.neutral00};
  }
`

export const DesktopAppHeader = styled(AppHeader)`
  height: 50px;
  position: fixed;

  .header-title {
    margin-left: 0;
  }
`

export const NavWrapper = styled.div`
  overflow-x: hidden;
  height: calc(100% - 50px);
  padding-bottom: 24px;
  margin-top: 16px;
`

export const MobileItems = styled.div`
  width: 100%;
  height: 100vh;
  top: 50px;
  left: 0px;
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme }) => theme.neutral01};
`

export const NavShadow = styled.div`
  position: absolute;
  top: 0px;
  left: 85vw;
  height: 50px;
  background-color: transparent;
  width: 15vw;
`

export const DesktopNavWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
`

export const MobileDivider = styled.div`
  background-color: ${({ theme }) => theme.neutral04};
  height: 100%;
  margin: 0 16px;
  width: 1px;
`

export const MobileMenuWrap = styled.div`
  display: flex;
  align-items: center;
`
