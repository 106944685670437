import React, { useContext, useEffect } from 'react'
import SitesIcon from '@ubnt/icons/SitesIcon'
import { Dropdown, type DropdownOption } from '@ubnt/ui-components/Dropdown'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'
import { MY_ORG, MY_SITES } from 'sharedConstants'
import { useOrganizationsQuery } from 'store/queries/useOrganizationsQuery'
import styled from 'theme/styled'
import { OrganizationRole } from 'api/organization'

export const OrganizationManagerDropdown = () => {
  const { organizations, isOwnerAndAdmin } = useOrganizationsQuery()
  const { accountViewId, handleAccountViewIdChange } =
    useContext(OrganizationContext)

  const options: DropdownOption[] = isOwnerAndAdmin
    ? []
    : [
        {
          label: 'My Sites',
          value: MY_SITES,
          image: <SitesIcon size="original" />,
        },
      ]

  organizations?.forEach(({ name, orgId, logoUrl, role }) =>
    options.push({
      label: name,
      value: role === OrganizationRole.ORG_OWNER ? MY_ORG : orgId,
      image: <StyledImage src={logoUrl} />,
    })
  )

  useEffect(() => {
    if (isOwnerAndAdmin && accountViewId === MY_SITES) {
      handleAccountViewIdChange(MY_ORG)
    }
  }, [accountViewId, handleAccountViewIdChange, isOwnerAndAdmin])

  return (
    <DropdownWrapper>
      <Dropdown
        options={options}
        variant="secondary"
        width="100%"
        value={accountViewId}
        onChange={(_options, value) =>
          handleAccountViewIdChange(value.toString())
        }
      />
    </DropdownWrapper>
  )
}

export const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`

const DropdownWrapper = styled.div`
  margin-bottom: 16px;
`
