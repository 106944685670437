import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Alert } from '@ubnt/ui-components/Alert'
import { Text } from '@ubnt/ui-components/aria'
import { orderBy } from 'lodash-es'
import { DateTime } from 'luxon'
import { useBillingUpcomingInvoicesQuery } from 'store/queries/useBillingUpcomingInvoicesQuery'
import { getAmount } from 'features/invoices/ui/utils'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { CountryFlag } from 'components/CountryFlag'
import {
  AlertTextContainer,
  PaymentAmount,
  PaymentAmountsWrapper,
  PaymentDueDate,
} from './Components.styles'
import NotificationIcon from '@ubnt/icons/NotificationIcon'
import { formatCurrencyNumberOptions } from 'utils/formatCurrencyNumberOptions'

type PaymentData = {
  amount: number
  region: StripeRegionCode
}

export const NextPaymentBanner = () => {
  const intl = useIntl()
  const { upcomingInvoices } = useBillingUpcomingInvoicesQuery()

  const nextPayments = orderBy(
    upcomingInvoices,
    ['next_payment_attempt'],
    ['asc']
  )

  const nextPaymentDate = DateTime.fromSeconds(
    nextPayments[0]?.next_payment_attempt ?? 0
  )

  const formattedDate = nextPaymentDate.toFormat('MMM d, yyyy')

  const nextPaymentAmounts = useMemo(() => {
    const payments: Record<string, PaymentData> = {}

    nextPayments.forEach((invoice) => {
      const invoiceDate = DateTime.fromSeconds(
        invoice?.next_payment_attempt ?? 0
      )
      if (
        !nextPaymentDate.hasSame(invoiceDate, 'day') ||
        invoice.amount_due === 0
      ) {
        return
      }
      if (payments[invoice.currency]) {
        payments[invoice.currency].amount += invoice.amount_due
      } else {
        payments[invoice.currency] = {
          amount: invoice.amount_due,
          region: invoice.region,
        }
      }
    })

    return payments
  }, [nextPaymentDate, nextPayments])

  if (
    !upcomingInvoices.length ||
    !upcomingInvoices.some((invoice) => invoice.amount_due > 0)
  ) {
    return null
  }

  const getBannerText = () => {
    return (
      <AlertTextContainer>
        <Text
          color="text-2"
          variant="heading-medium"
          style={{ marginRight: '4px' }}
        >
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_NEXT_PAYMENT" />
        </Text>
        <PaymentAmountsWrapper>
          {Object.entries(nextPaymentAmounts).map(
            ([currency, { amount, region }]) => (
              <PaymentAmount
                color="text-1"
                variant="heading-medium"
                key={`amount-${region}`}
              >
                <CountryFlag countryCode={region} noMargin />
                {intl.formatNumber(
                  getAmount(amount, currency),
                  formatCurrencyNumberOptions(currency)
                )}
              </PaymentAmount>
            )
          )}
        </PaymentAmountsWrapper>
        <PaymentDueDate color="text-3" variant="body-secondary">
          <FormattedMessage
            id="SETTINGS_SUBSCRIPTIONS_NEXT_PAYMENT_DATE"
            values={{ date: formattedDate }}
          />
        </PaymentDueDate>
      </AlertTextContainer>
    )
  }

  return (
    <Alert Icon={<NotificationIcon variant="fill" />} text={getBannerText()} />
  )
}
