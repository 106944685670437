import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import { useBillingInvoicesApi } from 'pages/subscriptions/hooks/useBillingInvoicesApi'
import {
  getSortedInvoices,
  mergeInvoices,
} from 'pages/subscriptions/hooks/utils'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'

export const useBillingOpenInvoicesQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsAuthenticated)
  const billingInvoicesApi = useBillingInvoicesApi()

  const rawOpenInvoices = useQuery({
    queryKey: [QueryKey.BILLING_OPEN_INVOICES],
    queryFn: billingInvoicesApi.getOpenInvoices,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const openInvoices = useMemo(() => {
    if (!rawOpenInvoices.data) {
      return []
    }

    const mergedInvoices = mergeInvoices(rawOpenInvoices.data)

    return getSortedInvoices(mergedInvoices)
  }, [rawOpenInvoices.data])

  return {
    openInvoices,
    areOpenInvoicesLoading: rawOpenInvoices.isFetching,
  }
}
