import { UcoreDevice } from 'api/nca/types'
import {
  adoptionTrouble,
  app,
  assistance,
  device,
  model,
  talkVip,
  tech,
  trouble,
  userType,
} from './ConfigOptions'
import {
  Assistance,
  Console,
  Device,
  FormConfig,
  NextSection,
  Tech,
  Trouble,
  UniFiStateValues,
} from './types'
import { parseNcaDevices } from 'features/support/utils'

export const getConfig = (
  ncaDevices: UcoreDevice[],
  isConsolesLoading: boolean
): FormConfig => {
  const hwFailureFlowSkipConsole = !ncaDevices.length && !isConsolesLoading

  const hwFailureNextSection: NextSection = hwFailureFlowSkipConsole
    ? {
        section: UniFiStateValues.DEVICE,
        condition: {
          field: UniFiStateValues.ASSISTANCE,
          value: [Assistance.hwFailure],
        },
      }
    : {
        section: UniFiStateValues.CONSOLE,
        condition: {
          field: UniFiStateValues.ASSISTANCE,
          value: [Assistance.hwFailure],
        },
      }

  return {
    assistance: {
      fieldId: 18680121603095,
      fieldTitle: 'SUPPORT_UNIFI_ASSISTANCE',
      options: assistance,
      nextSection: [
        {
          section: UniFiStateValues.TROUBLE,
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.setupAdopt, Assistance.adminUpdates],
          },
        },
        {
          section: UniFiStateValues.CONSOLE,
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.performOperation],
          },
        },
        hwFailureNextSection,
        {
          section: 'info',
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.featureConfig],
          },
        },
      ],
    },
    console: {
      fieldId: 1500011190041,
      fieldTitle: 'SUPPORT_UNIFI_CONSOLE',
      options: parseNcaDevices(ncaDevices),
      nextSection: [
        // The order here is important. This has to be above the others for correct rendering.
        {
          section: UniFiStateValues.USER_TYPE,
          condition: {
            field: UniFiStateValues.CONSOLE,
            value: [Console.unifiPlayDevice],
          },
        },
        {
          section: UniFiStateValues.APP,
          condition: {
            field: UniFiStateValues.TROUBLE,
            value: [Trouble.deviceAdoption],
          },
        },
        {
          section: UniFiStateValues.DEVICE,
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.hwFailure],
          },
        },
        {
          section: UniFiStateValues.USER_TYPE,
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.adminUpdates],
          },
        },
        {
          section: UniFiStateValues.TECH,
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.performOperation, Assistance.featureConfig],
          },
        },
      ],
    },
    device: {
      fieldId: 18680380890263,
      fieldTitle: 'SUPPORT_UNIFI_DEVICES',
      options: device,
      nextSection: [
        {
          section: UniFiStateValues.TROUBLE,
          condition: {
            field: UniFiStateValues.DEVICE,
            value: Object.values(Device).filter(
              (value) => value !== Device.playDevice
            ),
          },
        },
        {
          section: UniFiStateValues.USER_TYPE,
          condition: {
            field: UniFiStateValues.DEVICE,
            value: [Device.playDevice],
          },
        },
      ],
    },
    model: {
      fieldId: 18769371922711,
      fieldTitle: 'SUPPORT_UNIFI_MODEL',
      options: model,
      nextSection: [{ section: UniFiStateValues.USER_TYPE }],
    },
    talkVip: {
      fieldId: 19241713333271,
      fieldTitle: 'SUPPORT_UNIFI_TALK',
      options: talkVip,
      nextSection: [{ section: UniFiStateValues.USER_TYPE }],
    },
    tech: {
      fieldId: 1500011190801,
      fieldTitle: 'SUPPORT_UNIFI_TECH',
      options: tech,
      nextSection: [
        {
          section: UniFiStateValues.TALK_VIP,
          condition: {
            field: UniFiStateValues.TECH,
            value: [Tech.talk],
          },
        },
        {
          section: UniFiStateValues.USER_TYPE,
          condition: {
            field: UniFiStateValues.TECH,
            value: [
              Tech.wifi,
              Tech.routingAndSwitching,
              Tech.securityAndVPN,
              Tech.protect,
              Tech.access,
              Tech.identity,
              Tech.connect,
              Tech.drive,
              Tech.talkRelay,
            ],
          },
        },
      ],
    },
    app: {
      fieldId: 1500011190801,
      fieldTitle: 'SUPPORT_UNIFI_APP',
      options: app,
      nextSection: [{ section: UniFiStateValues.USER_TYPE }],
    },
    adoptionTrouble: {
      fieldId: 18679855353623,
      fieldTitle: 'SUPPORT_UNIFI_ADOPTION_TROUBLE',
      options: adoptionTrouble,
      nextSection: [{ section: UniFiStateValues.CONSOLE }],
    },
    trouble: {
      fieldId: 18679855353623,
      fieldTitle: 'SUPPORT_UNIFI_TROUBLE',
      options: trouble,
      nextSection: [
        {
          section: UniFiStateValues.MODEL,
          condition: {
            field: UniFiStateValues.TROUBLE,
            value: [Trouble.firstTimeSetup],
          },
        },
        {
          section: UniFiStateValues.ADOPTION_TROUBLE,
          condition: {
            field: UniFiStateValues.TROUBLE,
            value: [Trouble.deviceAdoption],
          },
        },
        {
          section: UniFiStateValues.USER_TYPE,
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.hwFailure],
          },
        },
        {
          section: UniFiStateValues.CONSOLE,
          condition: {
            field: UniFiStateValues.ASSISTANCE,
            value: [Assistance.adminUpdates],
          },
        },
      ],
    },
    userType: {
      fieldId: 9383100357271,
      fieldTitle: 'SUPPORT_UNIFI_USER_TYPE',
      options: userType,
      nextSection: [{ section: UniFiStateValues.DESCRIPTION }],
    },
    description: {
      fieldId: 0,
      fieldTitle: 'SUPPORT_DESCRIPTION_QUESTION',
      options: null,
      nextSection: null,
    },
    info: {
      fieldId: 1,
      fieldTitle: '',
      options: null,
      nextSection: null,
    },
    attachments: {
      fieldId: 10138128074775,
      fieldTitle: 'SUPPORT_ATTACHMENT_QUESTION',
      options: null,
      nextSection: null,
    },
    supportFile: {
      fieldId: 7081318973463,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    totalDevices: {
      fieldId: 18787629510807,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    mac: {
      fieldId: 1500011311782,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    version: {
      fieldId: 1500011311842,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    fwVersion: {
      fieldId: 10005292168215,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    releaseChannel: {
      fieldId: 1900002967245,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    appVersion: {
      fieldId: 1500011311902,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    appVersions: {
      fieldId: 9908053402519,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    sentryUserId: {
      fieldId: 1500011261181,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    sentryEventId: {
      fieldId: 1900002967305,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    totalConsoles: {
      fieldId: 10442648931991,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
    rawData: {
      fieldId: 1500011191181,
      fieldTitle: null,
      options: null,
      nextSection: null,
      hide: true,
    },
  }
}
