import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PdfFileIcon } from '@ubnt/icons'
import SkeletonLoader from '@ubnt/ui-components/SkeletonLoader/SkeletonLoader'
import Table from '@ubnt/ui-components/SkeletonLoader/Skeleton/Table'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { getCleanInvoiceLines } from 'features/invoices/ui/utils'
import { InvoicePaymentsSection } from 'features/invoices/ui/InvoicePaymentsSection'
import InvoiceHeader from 'features/invoices/ui/InvoiceHeader'
import InvoiceItemsTable from 'features/invoices/ui/InvoiceItemsTable'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { FlexWrapper } from 'components/SharedComponents'
import { motifPalette } from 'theme'
import { useCreateInvoicePDF } from '../../../../features/subscriptions/ui/hooks/useCreateInvoicePDF'
import { useBillingInvoiceLinesQuery } from 'store/queries/useBillingInvoiceLinesQuery'
import { InvoiceContext } from 'pages/subscriptions/components/InvoiceContext'

const {
  api: { accountBE },
} = __CONFIG__

const InvoiceModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const {
    openInvoice,
    setOpenInvoice,
    setIsUpcomingInvoice,
    isUpcomingInvoice,
  } = useContext(InvoiceContext)
  const { extraInvoiceLines, refetchInvoiceLines, areInvoiceLinesLoading } =
    useBillingInvoiceLinesQuery(
      openInvoice?.id,
      openInvoice?.region,
      openInvoice?.lines.has_more
    )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (
      openInvoice?.lines.has_more &&
      !areInvoiceLinesLoading &&
      !extraInvoiceLines
    ) {
      refetchInvoiceLines()
    }
  }, [
    areInvoiceLinesLoading,
    extraInvoiceLines,
    openInvoice?.lines.has_more,
    refetchInvoiceLines,
  ])

  const { createPDFLoading } = useCreateInvoicePDF(
    openInvoice?.id,
    openInvoice?.region
  )

  const invoiceLinesData = useMemo(() => {
    if (extraInvoiceLines?.length && openInvoice?.lines?.has_more) {
      return extraInvoiceLines
    }

    if (openInvoice) {
      return openInvoice.lines.data
    }
  }, [openInvoice, extraInvoiceLines])

  useEffect(() => {
    if (areInvoiceLinesLoading) {
      setIsLoading(true)
    }

    if (!areInvoiceLinesLoading) {
      setIsLoading(false)
    }

    if (openInvoice && !openInvoice.lines.has_more && isLoading) {
      setIsLoading(false)
    }
  }, [areInvoiceLinesLoading, openInvoice, isLoading])

  if (!openInvoice || !invoiceLinesData) {
    return null
  }

  const invoiceItems = getCleanInvoiceLines(invoiceLinesData)

  const handleCloseModal = () => {
    setOpenInvoice()
    isUpcomingInvoice && setIsUpcomingInvoice?.(false)
    onClose?.()
  }

  return (
    <GenericModal
      isOpen={isOpen}
      title={
        <FormattedMessage
          id={
            isUpcomingInvoice
              ? 'SETTINGS_UPCOMING_INVOICE_MODAL'
              : 'COMMON_ACTION_PREVIEW'
          }
        />
      }
      headerBorderBottom
      size="large"
      height="large"
      isInvoiceModal
      onRequestClose={handleCloseModal}
      actions={
        isUpcomingInvoice
          ? []
          : [
              {
                text: (
                  <StyledAnchorTag
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${accountBE.base}/${
                      accountBE.paths.billingInvoice
                    }/${openInvoice?.id}/${openInvoice?.region?.toUpperCase()}`}
                  >
                    <StyledPdfFileIcon />
                    <FormattedMessage id="COMMON_ACTION_DOWNLOAD" />
                  </StyledAnchorTag>
                ),
                variant: 'primary',
                disabled: isLoading,
                style: { height: '32px' },
                loader: createPDFLoading ? 'dots' : undefined,
              },
            ]
      }
      headerClassName="invoice-header"
      contentClassName="invoice-content"
    >
      <Wrapper>
        <InvoiceHeader
          invoice={openInvoice}
          siteName={invoiceLinesData?.[0]?.metadata?.deviceName || ''}
        />
        <FlexWrapper column padding={20}>
          {isLoading ? (
            <SkeletonLoader>
              <Table rowCounts={6} colCounts={4} rowHeight={64} />
            </SkeletonLoader>
          ) : (
            <InvoiceItemsTable
              items={invoiceItems}
              discount={openInvoice.discount}
              discountAmount={openInvoice.total_discount_amounts?.[0]?.amount}
              total={openInvoice.total}
              currency={openInvoice.currency}
              metadata={openInvoice.metadata}
              lines={openInvoice.lines.data}
            />
          )}
          <InvoicePaymentsSection invoice={openInvoice} />
        </FlexWrapper>
      </Wrapper>
    </GenericModal>
  )
}

export const INVOICE_MODAL_ID = 'INVOICE_MODAL_ID'

const WrappedInvoiceModal: React.FC = () => (
  <ModalWrapper modalId={INVOICE_MODAL_ID}>
    <InvoiceModal />
  </ModalWrapper>
)

export default WrappedInvoiceModal

const Wrapper = styled.div`
  min-height: 400px;
`

const StyledPdfFileIcon = styled(PdfFileIcon)`
  margin-right: 4px;
  margin-left: -3px;
  width: 20px;
  height: 20px;
`
const StyledAnchorTag = styled.a`
  color: ${motifPalette['dark'].text0};
  &:hover {
    color: ${motifPalette['dark'].text1};
  }
`
