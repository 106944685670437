import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardCountry,
  CardInfo,
  CardName,
  CardsTableItem,
  DefaultPill,
  MethodIcon,
} from './Components.styles'
import { validStripeCountries } from 'features/stripe/ui/regions'
import { CardType } from 'features/payment-methods/modules/types'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { useUpdateCustomer } from 'store/mutations/billingCustomer/useUpdateCustomer'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { CountryFlag } from 'components/CountryFlag'
import { CardListPopover } from './CardListPopover'
import { getPaymentMethodIcon, parsePaymentMethodBrand } from '../utils'
import { TruncatedText } from 'components/TruncatedText'

interface Props {
  card: CardType
}

export const CardRow: React.FC<Props> = ({ card }) => {
  const { isBillingCustomerLoading } = useBillingCustomerQuery()
  const { updateBillingCustomer } = useUpdateCustomer()
  const [settingDefault, setSettingDefault] = useState<string | null>(null)

  const getCardInfo = (): React.ReactNode => {
    const brand = card.card?.brand
    const cardIconSrc = getPaymentMethodIcon(brand)
    const brandDisplayName = card.card?.display_brand
    const paymentMethodBrand =
      brand === 'carnet' || !brandDisplayName ? brand : brandDisplayName
    const cardBrand = paymentMethodBrand ? (
      parsePaymentMethodBrand(paymentMethodBrand)
    ) : (
      <></>
    )
    const cardNumber = `••••${card.card?.last4}`

    return (
      <CardInfo>
        <MethodIcon src={cardIconSrc} />
        {cardBrand}
        {cardNumber}
        {card.is_default && (
          <DefaultPill>
            <FormattedMessage id="SETTINGS_PAYMENTS_DEFAULT" />
          </DefaultPill>
        )}
      </CardInfo>
    )
  }

  const getExpirationDate = () => {
    if (!card.card) {
      return <></>
    }

    let month: string | number = card.card.exp_month

    if (month < 10) {
      month = `0${card.card.exp_month}`
    }

    return `${month}/${card.card.exp_year}`
  }

  const getRegion = () => {
    const country = validStripeCountries.find(
      (country) =>
        country.code === card.billing_details.address?.country?.toLowerCase()
    )

    if (!country) {
      return null
    }

    return (
      <CardCountry>
        <CountryFlag countryCode={country.code} noMargin />
        <TruncatedText truncateAt={19} text={country.name} />
      </CardCountry>
    )
  }

  const handleSetDefault = (cardId: string, cardRegion: StripeRegionCode) => {
    updateBillingCustomer({
      data: { default_source: cardId },
      region: cardRegion,
    })
    setSettingDefault(cardId)
  }

  useEffect(() => {
    if (settingDefault && !isBillingCustomerLoading) {
      setSettingDefault(null)
    }
  }, [isBillingCustomerLoading, settingDefault])

  return (
    <>
      <CardsTableItem variant="body-primary" color="text-2">
        {getCardInfo()}
      </CardsTableItem>
      <CardsTableItem
        variant="body-primary"
        color="text-2"
        className="extra-data"
      >
        <CardName>{card.billing_details.name ?? ''}</CardName>
      </CardsTableItem>
      <CardsTableItem
        variant="body-primary"
        color="text-2"
        className="extra-data"
      >
        {getExpirationDate()}
      </CardsTableItem>
      <CardsTableItem
        variant="body-primary"
        color="text-2"
        className="extra-data"
      >
        {getRegion()}
      </CardsTableItem>
      <CardsTableItem variant="body-primary" color="text-2">
        <CardListPopover card={card} handleSetDefault={handleSetDefault} />
      </CardsTableItem>
    </>
  )
}
