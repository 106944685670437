import React, { useContext, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Popover } from '@ubnt/ui-components/Popover'
import { PopoverButton, PopoverIcon } from '../Components.styles'
import { useSubscriptionCancel } from 'pages/subscriptions/hooks/useSubscriptionCancel'
import { useSubscriptionPaymentMethods } from 'pages/subscriptions/hooks/useSubscriptionPaymentMethod'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { showSubscriptionFailedPaymentButtons } from 'features/subscriptions/utils'
import { ProductName } from 'types/enums'
import { InvoiceContext } from '../../InvoiceContext'
import { getSubscriptionCancelModalId } from '../utils'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'

interface Props {
  subscription: FullSubscription
  group: string
}

export const SubscriptionServicePopover: React.FC<Props> = ({
  subscription,
  group,
}) => {
  const { onCancelSubscription } = useSubscriptionCancel()
  const { openChoosePaymentMethodModal } = useSubscriptionPaymentMethods()
  const { handlePayInvoice } = useSubscriptionPaymentMethods()
  const { handleOpenInvoice, setIsUpcomingInvoice } = useContext(InvoiceContext)
  const { orgToFetch } = useContext(OrganizationContext)

  const isTalk = group === ProductName.UNIFI_TALK

  const subHasDefaultSource = useMemo(() => {
    return (
      !subscription.isSuspended &&
      (subscription.default_source || subscription.default_payment_method)
    )
  }, [subscription])

  if (orgToFetch) {
    return null
  }

  const showPayInvoice =
    showSubscriptionFailedPaymentButtons(subscription) && subHasDefaultSource

  const { cancelAtPeriodEnd, status, upcomingInvoice } = subscription

  const isSubscriptionCancelled = status === 'canceled' || cancelAtPeriodEnd

  const cancelSubscriptionModalId = getSubscriptionCancelModalId(group)

  if (isSubscriptionCancelled && !showPayInvoice && !upcomingInvoice) {
    return null
  }

  return (
    <Popover
      align="bottomLeft"
      renderToggle={() => <PopoverIcon size="large" />}
      size="auto"
      classNameCard="subscription-services-popover__card"
    >
      {upcomingInvoice && (
        <PopoverButton
          variant="tertiary"
          onClick={() => {
            handleOpenInvoice?.(upcomingInvoice)
            setIsUpcomingInvoice?.(true)
          }}
        >
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_POPOVER_INVOICE" />
        </PopoverButton>
      )}
      {showPayInvoice && (
        <PopoverButton
          variant="tertiary"
          onClick={() => handlePayInvoice(subscription)}
        >
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_POPOVER_PAY" />
        </PopoverButton>
      )}
      {!isTalk && (
        <PopoverButton
          variant="tertiary"
          onClick={() => openChoosePaymentMethodModal(subscription)}
        >
          <FormattedMessage
            id={
              subHasDefaultSource
                ? 'SETTINGS_SUBSCRIPTIONS_POPOVER_EDIT'
                : 'SETTINGS_SUBSCRIPTIONS_POPOVER_CHOOSE'
            }
          />
        </PopoverButton>
      )}
      {!isSubscriptionCancelled && cancelSubscriptionModalId && (
        <PopoverButton
          variant="tertiary"
          onClick={() =>
            onCancelSubscription(cancelSubscriptionModalId, subscription)
          }
        >
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_POPOVER_CANCEL" />
        </PopoverButton>
      )}
    </Popover>
  )
}
