import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import styled from 'theme/styled'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { useBillingUiBusinessProfileQuery } from 'store/queries/useBillingUiBusinessProfileQuery'

export const BusinessProfile: React.FC<{ region: StripeRegionCode }> = ({
  region,
}) => {
  const { uiBusinessProfiles, areBusinessProfilesLoading } =
    useBillingUiBusinessProfileQuery()
  const businessProfile = uiBusinessProfiles.find(
    (profile) => profile.region === region
  )

  if (areBusinessProfilesLoading) {
    return (
      <SkeletonLoader>
        <Wrapper>
          <Text width={80} />
          <Text width={180} />
          <Text width={180} />
        </Wrapper>
      </SkeletonLoader>
    )
  }

  if (!businessProfile) {
    return null
  }

  const { name, support_address } = businessProfile.value

  return (
    <>
      <span>{name}</span>
      <span>
        {support_address?.line1}
        {support_address?.line2 ? ` - ${support_address.line2}, ` : ''}
      </span>
      <span>
        {support_address?.city}, {support_address?.postal_code},
        {support_address?.state ? ` ${support_address.state}, ` : ''}
        {support_address?.country}
      </span>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
