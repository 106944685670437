import type Stripe from 'stripe'
import { fetchRequestWithCookies } from '../axios'
import { StripeRegionCode } from 'features/stripe/ui/types'

const {
  api: { billing },
} = __CONFIG__

type UiTaxIdWithRegion = Record<StripeRegionCode, Stripe.ApiList<Stripe.TaxId>>

type BusinessProfileWithRegion = Record<
  StripeRegionCode,
  Stripe.Account.BusinessProfile
>

export class BillingUiApi {
  public getAllTaxIds = async () => {
    const url = new URL(billing.paths.uiTaxId, billing.base)
    const { data } = await fetchRequestWithCookies<UiTaxIdWithRegion>(url.href)
    return data
  }

  public getAllBusinessProfiles = async () => {
    const url = new URL(billing.paths.businessProfile, billing.base)
    const { data } = await fetchRequestWithCookies<BusinessProfileWithRegion>(
      url.href
    )
    return data
  }
}
