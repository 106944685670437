import React from 'react'

import {
  Content,
  ContentWrapper,
  Sidebar,
  SidebarWrapper,
  Wrapper,
} from 'components/sidebarLayoutProvider/SidebarLayoutProvider.styles'
import { useAppView } from 'utils/useAppView'
import { ProfileCard } from 'components/profileCard/ProfileCard'
import { SidebarNavigation } from 'components/navigation/Navigation'
import { OrganizationManager } from 'components/navigation/OrganizationManager'

export const SidebarLayoutProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [isAppView] = useAppView()
  return (
    <Wrapper $isAppView={isAppView}>
      <SidebarWrapper>
        <Sidebar $isAppView={isAppView}>
          <ProfileCard />
          <OrganizationManager />
          <SidebarNavigation />
        </Sidebar>
      </SidebarWrapper>
      <ContentWrapper>
        <Content>{children}</Content>
      </ContentWrapper>
    </Wrapper>
  )
}
