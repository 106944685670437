import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../types'
import { OrganizationRole, OrganizationsApi } from 'api/organization'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { useSelector } from 'react-redux'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { useMemo } from 'react'

const organizationsApi = new OrganizationsApi()

export enum AccountType {
  ORG_OWNER = 'ORG_OWNER',
  PERSONAL_ACCOUNT = 'PERSONAL_ACCOUNT',
  ORG_ADMIN = 'ORG_ADMIN',
}

export const useOrganizationsQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const organizations = useQuery({
    queryKey: [QueryKey.ORGANIZATIONS],
    queryFn: organizationsApi.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const userOrganizations = useMemo(() => {
    if (!organizations.data) {
      return []
    }
    return organizations.data.filter((org) => !!org.name)
  }, [organizations.data])

  const accountType = useMemo(() => {
    if (userOrganizations.length) {
      const isOrgOwner = userOrganizations.some(
        ({ role }) => role === OrganizationRole.ORG_OWNER
      )
      return isOrgOwner ? AccountType.ORG_OWNER : AccountType.ORG_ADMIN
    }

    return AccountType.PERSONAL_ACCOUNT
  }, [userOrganizations])

  const isOwnerAndAdmin = useMemo(() => {
    return (
      accountType === AccountType.ORG_OWNER &&
      userOrganizations.some(({ role }) => role === OrganizationRole.ORG_ADMIN)
    )
  }, [accountType, userOrganizations])

  return {
    organizations: userOrganizations,
    isOrganizationsLoading: organizations.isFetching,
    isOwnerAndAdmin,
    accountType,
  }
}
