import { fetchRequestWithCookies, postRequestWithCookies } from 'api/axios'

type ChatScheduleResponse = { isChatOpen: boolean; isAdminFlagOn: boolean }

type ChatAdminScheduleResponse = { isAdminFlagOn: boolean }

const {
  api: { accountBE },
} = __CONFIG__

export class ChatApi {
  getSchedule = async () => {
    const url = new URL(accountBE.paths.chatSchedule, accountBE.base)

    const { data } = await fetchRequestWithCookies<ChatScheduleResponse>(
      url.href
    )
    return data
  }

  getAdminScheduleOverride = async () => {
    const url = new URL(accountBE.paths.chatScheduleAdmin, accountBE.base)

    const { data } = await fetchRequestWithCookies<ChatAdminScheduleResponse>(
      url.href
    )

    return data
  }

  updateAdminScheduleOverride = async (data: ChatAdminScheduleResponse) => {
    const url = new URL(accountBE.paths.chatScheduleAdmin, accountBE.base)

    await postRequestWithCookies(url.href, {
      isAdminFlagOn: data.isAdminFlagOn,
    })
  }
}
