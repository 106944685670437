import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { MY_ORG, MY_SITES } from 'sharedConstants'
import { QueryKey } from 'store/types'

type OrganizationContextType = {
  accountViewId: string
  handleAccountViewIdChange: (value: string) => void
  orgToFetch: string | null
}

export const OrganizationContext = React.createContext<OrganizationContextType>(
  {
    accountViewId: MY_SITES,
    handleAccountViewIdChange: () => {},
    orgToFetch: null,
  }
)

const personalAccountIds = [MY_SITES, MY_ORG]

export const OrganizationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accountViewId, setAccountView] = useState<string>(MY_SITES)
  const queryClient = useQueryClient()

  const handleAccountViewIdChange = (value: string) => {
    setAccountView(value)
  }

  const orgToFetch = personalAccountIds.some((id) => id === accountViewId)
    ? null
    : accountViewId

  useEffect(() => {
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_INVOICES],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_INVOICE_YEARS],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_UPCOMING_INVOICES],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_OPEN_INVOICES],
    })
  }, [orgToFetch, queryClient])

  return (
    <OrganizationContext.Provider
      value={{ accountViewId, handleAccountViewIdChange, orgToFetch }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
