import {
  ORGANIZATION_ACCOUNT_TAG,
  ZENDESK_FOLLOW_UP_REQUEST_TAG,
  ZENDESK_REQUEST_TAG,
} from 'features/support/constants'
import { TalkVip } from '../config/types'

export const getQueryTags = (tags: string | null | undefined) => {
  if (tags) return tags.trim().split(/\s+/)
  return []
}

export const getTags = ({
  isFollowUp,
  isTalkVip,
  isOrgAccount,
  queryTags,
}: {
  isFollowUp?: boolean
  isTalkVip?: boolean
  isOrgAccount?: boolean
  queryTags?: string | null
}) => {
  const tags = [ZENDESK_REQUEST_TAG]

  if (isFollowUp) {
    tags.push(ZENDESK_FOLLOW_UP_REQUEST_TAG)
  }

  if (isTalkVip) {
    tags.push(TalkVip.vip)
  }

  if (isOrgAccount) {
    tags.push(ORGANIZATION_ACCOUNT_TAG)
  }

  const customTags = getQueryTags(queryTags)

  if (customTags) {
    tags.push(...customTags)
  }

  return tags
}
