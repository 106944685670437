import { useSelector } from 'react-redux'
import { omit, reduce } from 'lodash-es'

import { getRequester } from 'features/support/unifi/utils/requestUtils'
import { selectProfileData } from 'modules/profile'
import {
  FIELD_FALLBACK_VALUE_NA,
  ORGANIZATION_ACCOUNT_TAG,
  ZENDESK_FOLLOW_UP_REQUEST_TAG,
  ZENDESK_REQUEST_TAG,
} from 'features/support/constants'
import { UcoreDevice } from 'api/nca/types'
import { ZDCustomFields } from 'features/support/types'

import { CustomFields, SupportFormState } from '../types'
import { getTotalNumberOfDevices } from '../../unifi/utils/getTotalNumberOfDevices'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { BillingSpecificService, Console, FormConfig } from '../config/types'
import { SpecificIssue } from 'features/support/mobility/config/types'

const { ZENDESK_UNIFI_FORM_KEY } = __CONFIG__

export const useFormatTicket = (config: FormConfig) => {
  const profile = useSelector(selectProfileData)
  const { accountType } = useOrganizationsQuery()

  const formatRequestedTicket = async (
    values: SupportFormState,
    selectedConsole?: UcoreDevice,
    sendsafelyAttachment?: string,
    followup_source_id?: string
  ) => {
    if (!profile) return
    const requester = getRequester(profile)

    const totalNumberOfDevices = await getTotalNumberOfDevices()

    const shadow = selectedConsole?.shadow.state.reported

    const consoleData = {
      version: shadow?.version,
      fwVersion: shadow?.hardware.firmwareVersion,
      releaseChannel: shadow?.releaseChannel,
    }

    let consoleId = values.console?.id

    if (
      values.billingSpecificService !== BillingSpecificService.identity &&
      !consoleId
    ) {
      consoleId = Console.undetected
    }

    let umrSpecificIssue: SpecificIssue.subscriptionIssue | undefined

    if (values.billingSpecificService === BillingSpecificService.umr) {
      umrSpecificIssue = SpecificIssue.subscriptionIssue
    }

    const initialValues: CustomFields = {
      billingSpecificService: values.billingSpecificService,
      console: consoleId,
      userType: values.userType,
      attachments: sendsafelyAttachment,
      totalDevices: totalNumberOfDevices,
      umrSpecificIssue,
      mac: values.console?.mac,
      ...consoleData,
      rawData: JSON.stringify(
        {
          billingSpecificService: values.billingSpecificService,
          console: consoleId,
          userType: values.userType,
          attachments: sendsafelyAttachment,
          totalDevices: totalNumberOfDevices,
          mac: values.console?.mac,
          umrSpecificIssue,
          ...consoleData,
        },
        null,
        2
      ),
    }

    const ticketFields = omit(config, 'description')

    const custom_fields = reduce<typeof ticketFields, ZDCustomFields>(
      ticketFields,
      (result, field, key) => {
        const id: number = field.fieldId
        const value = initialValues[key as keyof CustomFields]
        if (!value) return result
        return [
          ...result,
          {
            id,
            value,
          },
        ]
      },
      []
    )

    return {
      followup_source_id: followup_source_id || FIELD_FALLBACK_VALUE_NA,
      ticket_form_id: ZENDESK_UNIFI_FORM_KEY,
      requester,
      tags: [
        ZENDESK_REQUEST_TAG,
        ...(followup_source_id ? [ZENDESK_FOLLOW_UP_REQUEST_TAG] : []),
        ...(accountType === AccountType.ORG_OWNER
          ? [ORGANIZATION_ACCOUNT_TAG]
          : []),
      ],
      subject: '',
      comment: { body: values.description },
      custom_fields,
    }
  }

  return { formatRequestedTicket }
}
