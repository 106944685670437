import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { find } from 'lodash-es'
import { BrowserUtils } from 'utils/browserUtils'
import { useDispatch } from 'react-redux'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { INVOICE_MODAL_ID } from 'pages/subscriptions/components/invoices/InvoiceModal'
import { setVisibleModal } from 'modules/modals'
import { InvoiceItem } from '../hooks/utils'
import { useInvoices } from '../hooks/useInvoices'
import { useBillingInvoiceYearsQuery } from 'store/queries/useBillingInvoiceYearsQuery'

type InvoiceContextType = {
  openInvoice?: StripeInvoice
  foundListWithInvoice?: InvoiceItem
  setOpenInvoice: (invoice?: StripeInvoice) => void
  handleOpenInvoice?: (invoice: StripeInvoice) => void
  isUpcomingInvoice: boolean
  setIsUpcomingInvoice?: (isUpcoming: boolean) => void
  selectedYear: string
  updateSelectedYear: (value: string) => void
}

const invoiceSearchParam = 'invoiceId'

export const InvoiceContext = React.createContext<InvoiceContextType>({
  setOpenInvoice: () => {},
  isUpcomingInvoice: false,
  selectedYear: '',
  updateSelectedYear: () => {},
})

export const InvoiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { invoiceYears, areInvoiceYearsFetched } = useBillingInvoiceYearsQuery()

  const [selectedYear, setSelectedYear] = useState<string>(invoiceYears[0])

  useEffect(() => {
    if (areInvoiceYearsFetched && invoiceYears.length) {
      setSelectedYear(invoiceYears[0])
    }
  }, [areInvoiceYearsFetched, invoiceYears])

  const [openInvoice, setOpenInvoice] = useState<StripeInvoice | undefined>()
  const [isUpcomingInvoice, setIsUpcomingInvoice] = useState(false)
  const dispatch = useDispatch()
  const invoiceId = BrowserUtils.getLocationQueryValue(invoiceSearchParam)
  const { allTableInvoices } = useInvoices()

  const updateSelectedYear = (value: string) => {
    setSelectedYear(value)
  }

  const foundListWithInvoice = useMemo(() => {
    return find(allTableInvoices, { children: [{ id: invoiceId }] })
  }, [allTableInvoices, invoiceId])

  const foundInvoice = useMemo(() => {
    return foundListWithInvoice?.children?.find(
      (invoice) => invoice.id === invoiceId
    )
  }, [foundListWithInvoice?.children, invoiceId])

  const handleOpenInvoice = useCallback(
    (invoice: StripeInvoice) => {
      setOpenInvoice(invoice)
      dispatch(setVisibleModal(INVOICE_MODAL_ID))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!foundInvoice) {
      return
    }

    handleOpenInvoice(foundInvoice)
  }, [foundInvoice, handleOpenInvoice])

  return (
    <InvoiceContext.Provider
      value={{
        openInvoice,
        setOpenInvoice,
        handleOpenInvoice,
        foundListWithInvoice,
        setIsUpcomingInvoice,
        isUpcomingInvoice,
        selectedYear,
        updateSelectedYear,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
}
