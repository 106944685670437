import { MY_ORG, MY_SITES } from 'sharedConstants'
import { AccountType } from 'store/queries/useOrganizationsQuery'

export const hideNavigationItem = (
  accountView: string,
  accountType: AccountType,
  isOwnerAndAdmin: boolean,
  hiddenForAdmins?: boolean
) => {
  const shouldHideForUser =
    accountType === AccountType.ORG_ADMIN ||
    (isOwnerAndAdmin && accountView !== MY_ORG)

  return hiddenForAdmins && accountView !== MY_SITES && shouldHideForUser
}
