import React from 'react'
import { SubscriptionServiceTableItem } from '../Components.styles'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { CountryFlag } from 'components/CountryFlag'
import { FormattedMessage, useIntl } from 'react-intl'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import InfoIcon from '@ubnt/icons/InfoIcon'
import { formatCurrencyNumberOptions } from 'utils/formatCurrencyNumberOptions'

interface Props {
  amount: number
  currency: string
  region: StripeRegionCode
  isCanceled?: boolean
}

export const Amount: React.FC<Props> = ({
  amount,
  currency,
  region,
  isCanceled,
}) => {
  const intl = useIntl()

  if (isCanceled) {
    return (
      <SubscriptionServiceTableItem>
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END_ROW" />
        <Tooltip
          tooltipClassName="customSubscriptionsTooltipWidth override-index"
          description={
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_CANCELED_TOOLTIP" />
          }
        >
          <InfoIcon size={20} isActive />
        </Tooltip>
      </SubscriptionServiceTableItem>
    )
  }

  return (
    <SubscriptionServiceTableItem>
      <CountryFlag countryCode={region} noMargin />
      {intl.formatNumber(amount, formatCurrencyNumberOptions(currency))}
    </SubscriptionServiceTableItem>
  )
}
