type Options = {
  style: 'currency'
  currency: string
  minimumFractionDigits: number
  maximumFractionDigits: number
}

export const formatCurrencyNumberOptions = (currency: string): Options => ({
  style: 'currency',
  currency,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})
